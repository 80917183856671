<template>
  <div>
    <v-subheader>
        {{$vuetify.lang.t('$vuetify.worksheet.entryAdjTitle')}}
        ({{$vuetify.lang.t('$vuetify.base.weight')}}: {{worksheetEntry.netWeight | formatNumber}} {{productInfo ? productInfo.measureWeight:''}})
    </v-subheader>
    <div>
        <v-text-field class="ma-4" type="number" outlined hide-details dense
            :suffix="productInfo ? productInfo.measureWeight:''" 
            v-model.number="adjustment.quantity" 
            :label="$vuetify.lang.t('$vuetify.base.adjustmentWeight')"  />
        <div class="ml-5 text-caption">
            {{$vuetify.lang.t('$vuetify.base.netWeight')}}: {{worksheetEntry.netWeight + (adjustment.quantity ? adjustment.quantity:0) | formatNumber}} {{productInfo ? productInfo.measureWeight:''}}</div>
        <v-textarea class="ma-4" v-model="adjustment.notes" rows="2" :label="$vuetify.lang.t('$vuetify.base.notes')" outlined/>
    </div>
    <div class="d-flex justify-space-around">
        <v-btn color="primary" @click="addAdjstment">{{$vuetify.lang.t('$vuetify.base.add')}}</v-btn>
        <v-btn @click="$emit('cancel')">{{$vuetify.lang.t('$vuetify.base.cancel')}}</v-btn>
    </div>

<div v-if="adjustments && adjustments.length > 0">
    <v-divider />
    <v-subheader >{{$vuetify.lang.t('$vuetify.worksheet.adjustmentHistory')}}</v-subheader>
    <v-simple-table class="mb-6">
        <thead>
            <tr>
                <th></th>
                <th>{{$vuetify.lang.t('$vuetify.base.weight')}}</th>
                <th>{{$vuetify.lang.t('$vuetify.base.notes')}}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for=" (adj, index) in adjustments" :key="adj.id">
                <td>{{index +1 }}</td>
                <td>
                    {{adj.quantity > 0 ? '+':''}}{{adj.quantity | formatNumber}} {{productInfo ? productInfo.measureWeight:'' }}
                </td>
                <td>{{adj.notes}}</td>
                <td>
                    <v-btn @click="deleteAdjstment(adj)" icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</div>
  </div>
</template>

<script>
export default {

    props: {
        worksheetEntry: {
            type: Object,
            required: true
        },
        productInfo: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        adjustment: {},
        adjustments: []
    }),
    mounted() {
        this.adjustment.processWorksheetInputId = this.worksheetEntry.id
        this.loadAdjustments();
    },
    methods: {
        loadAdjustments(){
            const params = {
                page: 0,
                size: 100,
                processWorksheetInputId: this.worksheetEntry.id
            }
            this.$axios.get('/processWorksheetInputAdjustment', {params})
                .then( (resp) => {
                    this.adjustments = resp.data.content;
                })
        },
        addAdjstment(){
            const data = Object.assign({}, this.adjustment);
            this.$axios.post('/processWorksheetInputAdjustment', data)
                .then( (resp) => {
                    data.id = resp.data;
                    const totalAdjstment = this.worksheetEntry.netAdjustmentWeight + data.quantity;
                    this.worksheetEntry.netAdjustmentWeight = totalAdjstment;
                    this.adjustments.push(data);
                    this.adjustment = {};
                    this.$emit('update:worksheetEntry', this.worksheetEntry);
                })
        },
        deleteAdjstment(item){
            this.$axios.delete('/processWorksheetInputAdjustment/'+item.id)
                .then(() => {
                    const totalAdjstment = this.worksheetEntry.netAdjustmentWeight - item.quantity;
                    this.worksheetEntry.netAdjustmentWeight = totalAdjstment;
                    this.adjustments.splice(this.adjustments.indexOf(a => a.id = item.id), 1);
                    this.$emit('update:worksheetEntry', this.worksheetEntry);
                })
        },
    }
}
</script>

<style>

</style>